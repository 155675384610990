






















































import { Vue, Component } from 'vue-property-decorator';
import { getActiveUser, logOut } from '@/store/authState';
import { getCustomDetails, getIsWhitelabel } from '@/store/customizerState';
import { updateStepIndex, updateProjectData, initEditor } from '@/store/editorState';
import { customizationDefaults } from '@/views/editor/projectData';

@Component
export default class Navbar extends Vue {
    menuItems = [
        {
            name: 'Dashboard',
            icon: 'compass.png',
            fa: 'compass'
        },
        {
            name: 'Create',
            icon: 'create.png',
            fa: 'plus-circle'
        },
        {
            name: 'Project',
            icon: 'project.png',
            fa: 'briefcase'
        },
        {
            name: 'Folder',
            icon: 'folder.png',
            fa: 'folder',
        },
        {
            name: 'Finder',
            icon: 'compass.png',
            fa: 'search'
        },
        {
            name: 'Settings',
            icon: 'cog.png',
            fa: 'cog'
        }
    ];

    get subscribedtoWhitelabel() {
        const user = getActiveUser(this.$store);
        return user.userRoles == 'OTO 1' || user.userRoles == 'OTO 6';
    }

    get siteName() {
        return getCustomDetails(this.$store)?.siteName;
    }

    get isWhitelabel() {
        const wl = getIsWhitelabel(this.$store);
        return wl;
    }

    getIcon(icon: string) {
        return require(`../../assets/images/${icon}`);
    }

    isActive(itemName: string) {
        return this.$route.name == itemName;
    }

    get firstName() {
        return getActiveUser(this.$store).firstName;
    }

    get lastName() {
        return getActiveUser(this.$store).lastName;
    }

    navigate(name: string) {
        if(name == 'Create'){
            updateStepIndex(this.$store, 0);
            initEditor(this.$store);
        }
        this.$router.push({ name })
    }

    logOut() {
        logOut(this.$store).then(() => this.$router.push('/auth/login'));
    }
}
