




























import { getActiveUser } from '@/store/authState';
import { getCustomDetails, getIsWhitelabel } from '@/store/customizerState';
import { resetTool } from '@/store/customizeState';
import { updateStepIndex, initEditor } from '@/store/editorState';
import {Vue, Component} from 'vue-property-decorator';

@Component
export default class Sidebar extends Vue {
     menuItemsConstant = [
        // {
        //     name: 'Dashboard',
        //     icon: 'compass.png',
        //     fa: 'compass'
        // },
        {
            name: 'Create',
            icon: 'create.png',
            fa: 'plus-circle'
        },
        {
            name: 'Project',
            icon: 'project.png',
            fa: 'briefcase'
        },
        {
            name: 'Folder',
            icon: 'folder.png',
            fa: 'folder',
        },
        {
            name: 'Finder',
            icon: 'compass.png',
            fa: 'search'
        },
        {
            name: 'Settings',
            icon: 'cog.png',
            fa: 'cog'
        },
    ];

    get menuItems() {
        let returnedContent = this.menuItemsConstant;
        if(this.showAgency()) {
            returnedContent = [ 
                ...returnedContent, 
                {
                    name: 'Agency',
                    icon: 'cog.png',
                    fa: 'building'
                },
            ]
        }

        if(!this.isWhitelabel){
            returnedContent = [ 
                ...returnedContent, 
                {
                    name: 'Suggest',
                    icon: 'cog.png',
                    fa: 'comment-alt'
                },
            ]
        }

        return returnedContent;
    }
    
    get siteName() {
        return getCustomDetails(this.$store)?.siteName;
    }

    get logoUrl() {
        return getCustomDetails(this.$store)?.logoUrl;
    }
    
    get isWhitelabel() {
        const wl = getIsWhitelabel(this.$store);
        return wl;
    }

    get user() {
        return getActiveUser(this.$store);
    }

    isActive(itemName: string) {
        return this.$route.name == itemName;
    }

    navigate(name: string) {
        if(name == 'Create'){
            updateStepIndex(this.$store, 0);
            initEditor(this.$store);
            resetTool(this.$store);
        }
        this.$router.push({ name })
    }

    showAgency() {
        return !(this.user.userRoles == 'Frontend' || this.user.userRoles == 'OTO 2');
    }

    getHelp() {
        var mail = document.createElement("a");
        mail.href = "mailto:support@xcod.io";
        mail.click();
    }
}
