






















import {Vue, Component} from 'vue-property-decorator';
import Navbar from '@/components/navbar/navbar.vue';
import EditorNavbar from '@/components/navbar/editorNavbar.vue';
import Sidebar from '@/components/navbar/sidebar.vue';
import { getIsWhitelabel } from '@/store/customizerState';

@Component({ components: { Navbar, EditorNavbar, Sidebar } })
export default class Dashboar extends Vue {
    get isEditor() {
        return this.$route.name!.includes('Create')
    }

    get slotContainer() {
        return {
            marginLeft: this.isEditor ? '0px' : '250px',
        }
    }

    get isWhitelabel() {
        return getIsWhitelabel(this.$store)
    }
}
