


















































































import { Vue, Component } from 'vue-property-decorator';
import { getFolders } from '@/store/folderState';
import { updateProjectData, getProjectData, isEditing, createProject, fetchQrCodePreview, generateImages, setCodePreview  } from '@/store/editorState';
import { logOut } from '@/store/authState';
import { getCustomDetails, getIsWhitelabel } from '@/store/customizerState';

@Component
export default class EditorNavbar extends Vue {
    get logoUrl() {
        return getCustomDetails(this.$store)?.logoUrl;
    }

    get siteName() {
        return getCustomDetails(this.$store)?.siteName;
    }

    get isEditing() {
        return isEditing(this.$store);
    }

    get isWhitelabel() {
        const wl = getIsWhitelabel(this.$store);
        return wl;
    }
    
    get projectData(){
        return getProjectData(this.$store)
    }
    get folders() {
        return getFolders(this.$store)
    }

    get selectedFolder() {
        return this.projectData.folder.name;
    }

    get projectTitle() {
        return this.projectData.title;
    }

    set projectTitle(value) {
        const payload = {
            ...this.projectData,
            title: value
        }
        updateProjectData(this.$store, payload)
    }

    setSelectedFolder(folder: any) {
        const payload = {
            ...this.projectData,
            folder: {
                name: folder.name,
                id: folder.id
            },
            folderId: folder.id
        }
        updateProjectData(this.$store, payload)
    }

    logOut() {
        logOut(this.$store).then(response =>  this.$router.push({ name: 'Login' }))
    }

    createProject() {
        if(!this.validate()) {
            return;
        }
        this.$toast.info('Saving project...')
        fetchQrCodePreview(this.$store).then((response: any) => {
            setCodePreview(this.$store, response.content);
            createProject(this.$store)
                .then(() => {
                    generateImages(this.$store, { svgString: response.content, projectId: this.projectData.id })
                }).catch(err => {
                    this.$toast.error('Something went wrong');
                })
        })
    }

    validate() {
        if(!this.projectData.folderId){
            this.$toast.error('Select a folder');
            return false;
        }

        return true;
    }
}
